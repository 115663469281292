let timeOutId = null;
let stopped = false;


document.addEventListener('turbolinks:load', () => {
  let index = 1;
  let radios = Array.from(document.querySelectorAll(`input[data-next]`));
  let carouselControls = document.querySelectorAll('.carousel-next, .carousel-prev, .carousel-bullet-item');
  stopped = false;

  let goToNext = () => {
    let radio = radios[index];
    let navMenuDiv = document.getElementById("nav-content");
    if (!stopped && radio && (navMenuDiv.classList.contains('hidden') == true)) {
      radio.click();
      index = radio.dataset.next;
      setTimeOutTurbolinks(goToNext, 2000);
    }
  }

  for (let control of carouselControls) {
    control.addEventListener('click', stop);
  }

  setTimeOutTurbolinks(goToNext, 2000);
});

let stop = () => {
  stopped = true;
  clearTimeout(timeOutId);
}

let setTimeOutTurbolinks = (callback, interval) => {
  clearTimeout(timeOutId);
  timeOutId = setTimeout(callback, interval);
  document.addEventListener("turbolinks:before-cache turbolinks:before-render", () => {
    clearTimeout(timeOutId);
  });
}
